import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";

import { useAuth } from "../../layout/AuthProvider";
import { toReadableDate } from "../../../utils/toReadableDate";

const Events = () => {
  const { isLoggedIn } = useAuth();
  const [refresh, setRefresh] = useState(false);
  const [events, setEvents] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [start, setStart] = useState("");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    axios
      .get("/api/v1/events", { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setEvents(res.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("Unauthorized");
        }
        console.error("Failed to get events", error);
      });
  }, [refresh]);

  const editEvent = (event) => {
    setShowModal(true);
    setEventToEdit(event);
    setTitle(event.title);
    setDescription(event.description);
    setLocation(event.location);
    setStart(event.start);
    // Calculate the number of hours from event.end and event.start
    const eventEnd = new Date(event.end);
    const eventStart = new Date(event.start);
    setHours(eventEnd.getHours() - eventStart.getHours());
    setMinutes(eventEnd.getMinutes() - eventStart.getMinutes());
  };

  const handleClose = () => {
    setShowModal(false);
    setEventToEdit(null);
    setTitle("");
    setDescription("");
    setLocation("");
    setStart("");
    setHours(0);
    setMinutes(0);
  };

  const handleNewEvent = () => {
    setShowModal(true);
    setEventToEdit("new");
  };

  const calculateEnd = () => {
    const endDate = new Date(start);
    endDate.setHours(endDate.getHours() + parseInt(hours));
    endDate.setMinutes(endDate.getMinutes() + parseInt(minutes));
    return endDate.getTime();
  };

  const onSubmit = () => {
    if (eventToEdit === null) {
      return;
    }
    if (
      !title ||
      !description ||
      !location ||
      !start ||
      !(hours > 0 || minutes > 0)
    ) {
      alert("All fields are required");
      return;
    }
    if (eventToEdit === "new") {
      axios
        .post("/api/v1/events", {
          title,
          description,
          location,
          start,
          end: calculateEnd(),
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("Event created", res.data);
            setRefresh(!refresh);
            handleClose();
          }
        })
        .catch((error) => {
          console.error("Failed to create event", error);
        });
      return;
    }
    axios
      .put(`/api/v1/events/${eventToEdit.id}`, {
        title,
        description,
        location,
        start,
        end: calculateEnd(),
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("Event updated", res.data);
          setRefresh(!refresh);
          handleClose();
        }
      })
      .catch((error) => {
        console.error("Failed to update event", error);
      });
  };

  const onEventCancel = () => {
    if (eventToEdit === null) {
      return;
    }
    axios
      .put(`/api/v1/events/${eventToEdit.id}/cancel`, null, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("Event cancelled", res.data);
          setRefresh(!refresh);
          handleClose();
        }
      })
      .catch((error) => {
        console.error("Failed to cancel event", error);
      });
  };

  const handleStartChange = (e) => {
    const localDate = new Date(e.target.value);
    setStart(localDate.getTime());
  };

  const toDateTimeLocal = (timestamps) => {
    const date = new Date(timestamps);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const renderModal = () => {
    if (eventToEdit === null) {
      return null;
    }
    return (
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Start</Form.Label>
              <Form.Control
                type="datetime-local"
                value={toDateTimeLocal(start)}
                onChange={handleStartChange}
                required
              />
            </Form.Group>
            <Row>
              <Form.Label className="mt-4">Duration</Form.Label>
              <Col sm={6}>
                <Form.Group controlId="hours" className="mb-3" sm={6}>
                  <Form.Label>Hours</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Hours"
                    name="hours"
                    value={hours}
                    onChange={(e) => setHours(e.target.value)}
                    min={0}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="minutes" className="mb-3" sm={6}>
                  <Form.Label>Minutes</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Minutes"
                    name="minutes"
                    value={minutes}
                    onChange={(e) => setMinutes(e.target.value)}
                    min={0}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {eventToEdit === "new" ? null : (
            <Button variant="danger" onClick={onEventCancel}>
              Cancel this Event
            </Button>
          )}
          <Button variant="primary" onClick={onSubmit}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderEvents = () => {
    if (events === null || events.length === 0) {
      return <div>No upcoming events</div>;
    }
    return (
      <>
        <h2>Upcoming events</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Location</th>
              <th>Start</th>
              <th>End</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event.id}>
                <td>{event.title}</td>
                <td>{event.description}</td>
                <td>{event.location}</td>
                <td>{toReadableDate(event.start)}</td>
                <td>{toReadableDate(event.end)}</td>
                <td>
                  <Button onClick={() => editEvent(event)}>Edit</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };
  if (isLoggedIn === null) {
    return <div>Loading...</div>;
  } else if (isLoggedIn === "admin") {
    return (
      <>
        {renderEvents()}
        {renderModal()}
        <Button onClick={() => handleNewEvent()}>Add Event</Button>
      </>
    );
  } else {
    return <div>Unauthorized</div>;
  }
};

export default Events;
